body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.grow{
  flexGrow: 1;
}

/* Local Fonts */
@font-face {
  font-family: 'TirantiSolidLET';
  src: local('TirantiSolidLET'), url(./Assets/fonts/Tiranti-Solid-LET.ttf) format('truetype');
}

@font-face {
  font-family: 'ArnoPro-Bold';
  src: local('ArnoPro-Bold'), url(./Assets/fonts/ArnoPro-BoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'ACADEMIC';
  src: local('ACADEMIC'), url(./Assets/fonts/ACADEMIC.TTF) format('truetype');
}

#style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb
{
	background-color: #000000;
}

